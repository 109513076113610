import { useState, useRef} from 'react'
import {  useOutletContext, Link } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'

import IconButton from "@mui/material/IconButton"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

import '../components/flex.css'
import '../components/login.css'

import axios from 'axios'
import serverUrl from '../config'

import {
	RegExpMatcher,
	englishDataset,
	englishRecommendedTransformers
}  from 'obscenity'

const ProfileEmail = () => {

    const matcher = new RegExpMatcher({
        ...englishDataset.build(),
        ...englishRecommendedTransformers
      });

    const exportData = useOutletContext()
    
  const [showPassword, setShowPassword] = useState(false)

  const [subEmail, changeEmail] = useState('')
  const [currentEmail, changeCurrentEmail] = useState(exportData.current.email)

  const [subPassword, changeSubPassword] = useState('')

  const [warning, setWarning] = useState('')
  const correct = useRef(false)

  const checkSignup = useRef(true)



  const submitEmail=(subEmail, subPassword)=>{

    correct.current = false

    const regexPassword = /^[^\s]+$/

    const regexEmail = /^[A-Z0-9_!#$%&'*+/=?`{|}~^-]+(?:\.[A-Z0-9_!#$%&'*+/=?`{|}~^-]+)*@[A-Z0-9-]+(?:\.[A-Z0-9-]+)*$/i

    const regexUpper = /([A-Z])+/g
    
    const regexLower = /([a-z])+/g

     // upper removes uppercase letters, lower removes lowercase letters

    const subEmailUpper = subEmail.replaceAll(regexUpper, '')

    const subEmailLower = subEmail.replaceAll(regexLower, '')

     // password
     if(subPassword === ''){
        setWarning('You have not entered a password')
        checkSignup.current = false
    }
    else if(subPassword.length > 256){
        setWarning('Password cannot exceed 256 characters')
        checkSignup.current = false
      }
    else if(subPassword.length < 8 ){
        setWarning('Password must be at least 8 characters')
        checkSignup.current = false
    }
    else if(!regexPassword.test(subPassword) ){
        setWarning('Password cannot include spaces')
        checkSignup.current = false
      }
     // email
    else if(subEmail === ''){
        setWarning('You have not entered an email')
        checkSignup.current = false
    }
    else if(subEmail.length > 256){
        setWarning('Email cannot exceed 256 characters')
        checkSignup.current = false
        } 
    else if(!regexEmail.test(subEmail)){
        setWarning('Please enter a valid email')
        checkSignup.current = false
    }
    else if(subEmail === currentEmail){
        setWarning('New email is the same as the current one')
        checkSignup.current = false
    }
    else if (matcher.hasMatch(subEmail) || matcher.hasMatch(subEmailLower) || matcher.hasMatch(subEmailUpper)) {
        checkSignup.current = false
        setWarning('Invalid email')
      }
    else
        checkSignup.current = true

        if(!checkSignup.current)
            return


    const baseUrl = serverUrl + 'profile/'
      
    const request = axios.post(baseUrl + `change-email`, {
          'email': subEmail,
          'password': subPassword

        }, { withCredentials:true
        }
      
    )

    request.then(response=>{
      if(response.status === 201){
        setWarning('Your email has been updated')

        changeCurrentEmail(subEmail)
        exportData.current.email = subEmail

        changeEmail('')
       changeSubPassword('')
       correct.current = true

      }
      else
        setWarning(`Invalid data format`)

    })
    .catch(function (error) {

        if(error.response === undefined){
            setWarning(`Unable to connect to server`)
            return
          }

      if(error.response.status  === 401){

        if(error.response.data === 'email exists')
            setWarning('New email belongs to another account')
        else if(error.response.data === 'wrong password')
            setWarning('Your password is incorrect')
        else
            setWarning(`Invalid data format`)   
      }
      else
        setWarning(`Invalid data format`)
    
    })
  }


  return (
<div className=" flexMenu">


<div className=" container-fluid " style={{paddingRight:'0', paddingLeft:'0'}}>
    <div className="row justifyFlex" style={{marginLeft:'0', marginRight:'0', columnGap:'16px'}} >
        <div className=" col-md-3  px-md-2 px-0  d-flex sticky-top " >
            <div id='menuParent' className=" d-flex flex-md-column flex-row flex-grow-1 align-items-center align-items-md-start px-3  text-white " >

                <ul className=" nav nav-pills flex-md-column flex-row 
                flex-nowrap flex-shrink-1 flex-md-grow-0 flex-grow-1 mb-md-auto mb-0 justify-content-center align-items-center align-items-md-start" id="menu" >
                    <li className='nav-item'>
                        <Link to='/profile' style={{textDecoration:'none', fontSize:'1.25rem', marginTop:'20px'}} className="nav-link px-md-0 px-0">
                        <span className="ms-1 d-none d-md-inline blackLink">Profile</span>
                        </Link>
                    </li>
                    <li>
                    <Link to='/profile/change-email' style={{textDecoration:'none', fontSize:'1.25rem'}} className="nav-link px-md-0 px-0">
                        <span className="ms-1 d-none d-md-inline blackLink">Change&nbsp;Email</span>
                        </Link>
                    </li>
                    <li>
                    <Link to='/profile/change-password' style={{textDecoration:'none', fontSize:'1.25rem'}} className="nav-link px-md-0 px-0">
                        <span  className="ms-1 d-none d-md-inline blackLink">Change&nbsp;Password</span>
                        </Link>
                    </li>
                    <li  className="dropdown waitSmall " >
                        <button className=" normalButton nav-link dropdown-toggle px-md-0 px-3" id="dropdown" data-bs-toggle="dropdown" 
                        aria-expanded="false" >
                            <span className="fs-5 bi-bootstrap" >View&nbsp;Profile</span>
                        </button>
                        <ul className=" dropdown-menu  text-small shadow " aria-labelledby="dropdown" >
                            <li><Link to='/profile' className="dropdown-item" >Profile</Link></li>
                            <li><Link to='/profile/change-email' className="dropdown-item" >Change&nbsp;Email</Link></li>
                            <li><Link to='/profile/change-password' className="dropdown-item" >Change&nbsp;Password</Link></li>
                  
                           
                        </ul>
                    </li>
                   
                </ul>

            </div>
        </div>
        <div className=" col "style={{padding:'0', minWidth:'0'}} >
            <main className="row overflow-auto" style={{margin:'0'}}>
       

        <div className="rightMenu profileColumn" >    

            <div className='center'>
                <h2>Change Your Email</h2>
                <label className="smallText"><b>Current Email</b></label><br/>
               
                <p>{currentEmail}</p>

                <p>Enter your password to change your email</p>
            
                    <label htmlFor='eyePassword' className="smallText "><b>Password</b></label><br/>
                    <OutlinedInput
                    className='field clearBackground'
                    size="small"
                    id="eyePassword"
                    type={showPassword? "text"  :  "password"}
                    value={subPassword}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>setShowPassword(!showPassword)}
                        edge="end"
                        >
                        {showPassword? <VisibilityOff />  : <Visibility /> }
                        </IconButton>
                    </InputAdornment>
                    }
                    name="password"
                
                    onChange={(e)=>changeSubPassword(e.target.value)}
                />
                <br/><br/>

                <label htmlFor='email' className="smallText"><b>New Email</b></label><br/>
                    <OutlinedInput id='email' autoComplete='email' className='field clearBackground' size="small" type="email" name="email" required 
                    value ={subEmail}
                    onChange={(e)=>changeEmail(e.target.value)} />
                    
                    {warning === ''? <p className = 'gaplessWarning'>&nbsp;</p>
                    :correct.current?
                    <p className = 'gaplessWarning correct' >{warning}</p>
                    :<p className = 'gaplessWarning' >{warning}</p>}

                    <button type="submit" className="btn btn-primary buttonFont" onClick={()=>submitEmail(subEmail, subPassword)}>Submit</button> 

                </div>
            </div>
           
            </main>

        </div>
    </div>
</div>
        
    
</div>
  )
}

export default ProfileEmail
import { useState, useRef} from 'react'
import { useOutletContext, Link } from 'react-router-dom'

import dogList from '../files/dog-breeds-list.json'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'

import '../components/flex.css'
import '../components/login.css'
import '../components/home.css'

import axios from 'axios'
import Select from 'react-select'
import serverUrl from '../config'


const ProfileIndex = () => {

 const exportData = useOutletContext()


  const [subDog, changeDog] = useState('')

  const[currentDog, changeCurrentDog] = useState(exportData.current.dog)


  const checkSignup = useRef(true)



  const submitChanges =(subDog)=>{

    if(subDog === '')
        checkSignup.current = false
    else
        checkSignup.current = true


    if(!checkSignup.current)
      return


    const baseUrl = serverUrl + 'profile/'
      
    const request = axios.post(baseUrl + `save-changes`, {
          'dog': subDog

        }, { withCredentials:true
        }
      
    )

    request.then(response=>{
      if(response.status === 201){
       // console.log('changes have been saved')

        changeCurrentDog(subDog)
        exportData.current.dog = subDog
      }

    })
    .catch(function (error) {
    
    })

  }


  return (
  <div>
<div className='flexMenu '>
    <div className=" container-fluid " style={{paddingRight:'0', paddingLeft:'0'}}>
    <div className="row justifyFlex" style={{marginLeft:'0', marginRight:'0', columnGap:'16px'}} >
        <div className=" col-md-3  px-md-2 px-0  d-flex sticky-top " >
            <div className="d-flex flex-md-column flex-row flex-grow-1 align-items-center 
            align-items-md-start px-3  text-white" id='menuParent'>

                <ul className=" nav nav-pills flex-md-column flex-row flex-nowrap flex-shrink-1 flex-md-grow-0 flex-grow-1 mb-md-auto mb-0 
                justify-content-center align-items-center align-items-md-start" id="menu" >
                    <li className='nav-item'>
                        <Link to='/profile' style={{textDecoration:'none', fontSize:'1.25rem', marginTop:'20px'}} className="nav-link px-md-0 px-0">
                        <span className="ms-1 d-none d-md-inline blackLink">Profile</span>
                        </Link>
                    </li>
                    <li>
                    <Link to='/profile/change-email' style={{textDecoration:'none', fontSize:'1.25rem'}} className="nav-link px-md-0 px-0">
                        <span className="ms-1 d-none d-md-inline blackLink">Change&nbsp;Email</span>
                        </Link>
                    </li>
                    <li>
                    <Link to='/profile/change-password' style={{textDecoration:'none', fontSize:'1.25rem'}} className="nav-link px-md-0 px-0">
                        <span  className="ms-1 d-none d-md-inline blackLink">Change&nbsp;Password</span>
                        </Link>
                    </li>
                    <li  className="dropdown waitSmall " >
                        <button className=" normalButton nav-link dropdown-toggle px-md-0 px-3" id="dropdown" data-bs-toggle="dropdown" 
                        aria-expanded="false" >
                            <span className="fs-5 bi-bootstrap" >View&nbsp;Profile</span>
                        </button>
                        <ul className=" dropdown-menu  text-small shadow " aria-labelledby="dropdown" >
                            <li><Link to='/profile' className="dropdown-item" >Profile</Link></li>
                            <li><Link to='/profile/change-email' className="dropdown-item" >Change&nbsp;Email</Link></li>
                            <li><Link to='/profile/change-password' className="dropdown-item" >Change&nbsp;Password</Link></li>
                  
                           
                        </ul>
                    </li>
                   
                </ul>

            </div>
        </div>
        <div className=" col "style={{padding:'0', minWidth:'0'}} >
            <main className="row overflow-auto " style={{margin:'0'}} >
        

            <div className="rightMenu  profileColumn" >
                <div className='center'>
                <h2>Profile</h2>
                    <label  className="smallText"><b>Username</b></label><br/>
                    <p >{exportData.current.username} <span className ='transparent'>Enter your password to change</span></p> 
                  

                    <label  className="smallText"><b>Email</b></label><br/>
                    <p>{exportData.current.email}</p>

                    <label  className="smallText"><b>Favorite Dog Breed</b></label><br/>
                    <p>{currentDog}</p>
                    
                    <Select menuPortalTarget={document.body}
                        defaultValue={
                          dogList.find((item) => item.label === exportData.current.dog)
                        }
                        options={dogList}
                        isSearchable = {true}
                        onChange={(opt)=>changeDog(opt.label)}
                    />
                    <br/>

                    <button type="submit" className="btn btn-primary buttonFont fitButton" onClick={()=>submitChanges(subDog)}>Save Changes</button> 
                
                
                      </div>
                  </div>
            
            </main>

        </div>
    </div>
</div>


</div>
</div>
  )
}

export default ProfileIndex
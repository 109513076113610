
import { chooseDogs } from '../components/data'
import {useLocation} from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import  ChoiceGrid  from '../components/game-screen'
import { useRef } from "react"
import dogNormal from '../files/dog-list.json'
import dogEasy from '../files/dog-list-easy.json'
import dogHard from '../files/dog-list-hard.json'

import 'bootstrap/dist/css/bootstrap.css'

import '../components/flex.css'

const Game = () => {

    const location = useLocation()

    const navigate = useNavigate()


    let dogs

    if(location.state.mode === 1){
        dogs = dogEasy
    }  
    else if(location.state.mode === 2 || location.state.mode === 4){
        dogs = dogNormal
    }
    else{
        dogs = dogHard
    }


    const initialRef = useRef(false)

    const questions = useRef(chooseDogs(dogs, location.state.number))
    

    const shuffle =(choices, index)=>{
      for(let i = choices.length - 1; i > 0; i--){
          let j = Math.floor(Math.random() * (i + 1));

          if(j === index){
            index = i
          }

          // swap choices[i] and choices[j]
          // destructuring syntax
          [choices[i], choices[j]] = [choices[j], choices[i]]
          
      }
      
      return index
   }

    const shuffleChoices =(questions)=>{
        for(let i = 0; i < questions.length; i++){
            questions[i].answerIndex = shuffle(questions[i].choices, questions[i].answerIndex)
            
        }
    }

    if(!initialRef.current){
      initialRef.current = true
      
      shuffleChoices(questions.current)
      
    }



    return (
    <div  style={{display:'flex', flexDirection:'column', minHeight:'0', flex:'1 1 0'}}>

        <div className="gameHeader">

            <button className = "gameButtons btn btn-primary" onClick={()=> navigate("/game")}>Game Options</button>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <button className = "gameButtons btn btn-primary" onClick={()=> window.location.reload()}>Play Again</button>
        </div>
        
        <ChoiceGrid  questionList={questions.current} numQuestions = {location.state.number} gameMode = {location.state.mode}/>
     
    </div>
    )
}


export default Game
